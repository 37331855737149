import React from "react";
import "./patient.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import PatientList from "./PatientDetails/PatientList";
// import patientData from "./PatientDetails/PatientData";
import { useState, useEffect } from "react";
import axios from "axios";
import { server_url } from "../../constants/constants";

function Patient() {
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${server_url}/patient/getPatients`)
      .then((response) => {
        setPatientData(response.data.data);
        setLoading(false);
        // console.log(patientData);
        // console.log("response data",response.data)
        // console.log("patient data", patientData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  // if (loading) {
  //   return <p>Loading...</p>; // Render a loading indicator
  // }

  // return (
  //     <div className="patient">
  //       <div className="sticky top-0 h-screen overflow-y-auto">
  //         <Sidebar />
  //       </div>

  //       <div className="patientContainer">
  //         <div className="sticky top-0 bg-white z-50 ">
  //           <Navbar />
  //         </div>

  //         <div className="container flex justify-center overflow-x-hidden bg-gray-100">
  //           <PatientList
  //             // columns={columns}
  //             data={patientData}
  //           // onDelete={}
  //           // handleDelete={handleDelete}
  //           />
  //         </div>
  //       </div>
  //     </div>
  // );

  return (
    <div className="md:flex block">
      {/* Sidebar */}
      <div className="md:flex-1 hidden md:flex sticky top-0 h-screen overflow-y-auto">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="md:flex-[5] block w-screen">
        <div className="sticky top-0 z-10">
          <Navbar />
        </div>
        <div className="container flex justify-center overflow-x-hidden bg-gray-100">
          <PatientList
              data={patientData}
            />
          </div>
        
      </div>
    </div>

  )
}

export default Patient;
