import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import postToCloudinaryImage from "../../helpers/postToCloudinaryImage";
import Webcam from "react-webcam";
import { server_url } from "../../constants/constants";
import { uploadFile } from "../../ApiCalls/dataUpload";
import { approveAlert, approveAllAlerts } from "../../ApiCalls/alertsApis";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Modal from "./Modal";
import { Link } from "react-router-dom";


const PrescriptionModal = ({ closeModal, user_id, onSuccess }) => {
    const [showDisapproveModal, setShowDisapproveModal] = useState(false);
    const [prescriptionImage, setPrescriptionImage] = useState("https://images.pexels.com/photos/1459505/pexels-photo-1459505.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459505.jpg&fm=jpg");
    const [isPrescriptionSelected, setIsPrescriptionSelected] = useState(false);
    const [groupedData, setGroupedData] = useState([]);
    const [isDisAll, setIsDisAll] = useState(false);

    const closeReasonModal = () => {
        setShowDisapproveModal(false);
    }

    const togglePrescription = () => {
        setIsPrescriptionSelected(isPrescriptionSelected);
    }

    const handleSetPrescriptionImage = (url) => {
        setPrescriptionImage(url)
    }

    const handleApproveAll = async (presId) => {
        await approveAllAlerts(presId);
        closeModal();

    }

    const handleDiapproveAll = () => {
        setIsDisAll(true);
        setShowDisapproveModal(true);

    }

    const handleApprove = async (item) => {
        await approveAlert(item.id, item.alarmId);
        closeModal();
        window.location.reload();


    }

    const handleDiapprove = () => {
        setIsDisAll(false);
        setShowDisapproveModal(true);

    }

    const closeImage = () => {
        setPrescriptionImage(null)
        setIsPrescriptionSelected(false)
    }

    useEffect(() => {
        var pres = localStorage.getItem("prescriptionAlerts");
        pres = JSON.parse(pres);
        console.log(pres);
        // group these prescriptions by presId
        const groupData = pres.reduce((acc, item) => {
            const { presId, ...rest } = item;
            if (!acc[presId]) {
                acc[presId] = [];
            }
            acc[presId].push(rest);
            return acc;
        }, {});
        setGroupedData(groupData);


    }, []);

    

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-black overflow-y-auto">
                <div className="p-7 ml-4 mr-4 mt-4 bg-white shadow-md border-t-4 border-primary rounded z-50 w-[80%] h-[100vh]">
                    <div className="header flex justify-between items-center border-b pb-2 mb-4">
                        <h2 className="text-2xl font-bold">Digitised Prescription Copy</h2>
                        <Link to={"/userProfile/"}>
                            <div className="rounded-lg text-primary border-2  border-primary w-40 py-2 justify-center flex shadow-lg"
                                style={{
                                    cursor: "pointer"
                                }}>
                                View Profile
                            </div>
                        </Link>
                        <div className="rounded-lg text-red-800 border-2  border-red-800 w-40 py-2 justify-center flex shadow-lg" onClick={closeModal}
                            style={{
                                cursor: "pointer"
                            }}
                        >Close</div>
                    </div>
                    <div className="flex flex-row">
                        {/* parent prescription container */}
                        <div className="flex flex-col w-full">
                            {Object.keys(groupedData).map((key, index) => (
                                <div class="prescriptionModal_container flex justify-center flex-col">
                                    <div class="w-full">
                                        <div class="left">
                                            <div className="flex-col">
                                                <div className=" bg-blue-300  flex-row flex justify-between items-center border-b p-2 mb-4">
                                                    <button><b>Prescription on  </b>{
                                                        groupedData[key][0].date.slice(0, 10)

                                                    }</button>
                                                    <div onClick={() => {
                                                        var url = groupedData[key][0].presImg;
                                                        setIsPrescriptionSelected(true)
                                                        // togglePrescription()
                                                        handleSetPrescriptionImage(url)
                                                    }}
                                                        style={{
                                                            cursor: "pointer",
                                                            textDecoration: "underline"

                                                        }}
                                                    >view prescription</div>
                                                    <div className="rounded-lg text-white border-2 bg-primary w-40 py-2 justify-center flex cursor-pointer shadow-lg hover:bg-primary-dark hover:text-white transition duration-300 ease-in-out transform hover:scale-105" onClick={() => { handleApproveAll(key) }}>Approve All</div>
                                                    <div className="rounded-lg text-white border-2 bg-red-800 w-40 py-2 justify-center flex shadow-lg cursor-pointer transition duration-300 ease-in-out transform  hover:text-white  hover:scale-105" onClick={handleDiapproveAll}>Disapprove All</div>
                                                </div>

                                                {/* child prescription container */}
                                                {

                                                    groupedData[key].map((item) => (
                                                        <div className="flex-row flex justify-between items-center border-b p-2 mb-4">
                                                            {showDisapproveModal && <Modal closeModal={closeReasonModal} disAll={isDisAll} item={item} presId={key} />}
                                                            <div>
                                                                <div className="font-bold">
                                                                    Alarm Description
                                                                </div>
                                                                <div>
                                                                    {item.desc}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="font-bold">
                                                                    {item.timesaday} {item.timesaday > 1 ? "times" : "time"} {item.isWeek ? "a week" : "a month"}
                                                                </div>
                                                                {/* map times a day with doses*/}
                                                                {item.doses.map((dose) => (
                                                                    <div>
                                                                        {dose}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                <div>
                                                                    {item.weekdays}
                                                                </div>
                                                            </div>
                                                            <button className="rounded-lg text-white border-2 bg-primary border-primary w-40 py-2 justify-center flex cursor-pointer shadow-lg hover:bg-primary-dark hover:text-white transition duration-300 ease-in-out transform hover:scale-105" onClick={
                                                                () => { handleApprove(item) }
                                                            }>Approve</button>
                                                            <button className="rounded-lg text-white border-2 bg-red-800 w-40 py-2 justify-center flex shadow-lg cursor-pointer transition duration-300 ease-in-out transform hover:scale-105" onClick={handleDiapprove}>Disapprove</button>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            isPrescriptionSelected ? (
                                <div className="w-[45%] relative">
                                    <div className="p-2">
                                        {/* <div className="absolute top-0 right-0 p-3 cursor-pointer">
                                            <div onClick={closeImage}>
                                                <HighlightOffIcon/>
                                            </div>
                                        </div> */}
                                        <img src={prescriptionImage} alt="" />
                                    </div>
                                </div>
                            ) : (
                                <div class="w-[45%] bg-gray-100 rounded-lg shadow-md flex justify-center items-center">
                                    <div class="p-4">
                                        Please click on view prescription to see the prescription image here.
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrescriptionModal;
