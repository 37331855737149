export const alarmTypeOptions = [
  {
    title: "Select Dialysis Parameter",
    label: "Dialysis",
  },
  {
    title: "Select Health Parameter",
    label: "Health Reading",
  },
  { label: "Diet Details" },
  {
    title: "Select Prescription",
    label: "Prescription",
  },
];

export const dosesOptions = [
  { label: "ml", value: "ml" },
  { label: "teaspoon", value: "teaspoon" },
  { label: "capsule", value: "capsule" },
  { label: "tablet", value: "tablet" },
];

export const timing = [
  { label: "Once", value: 1 },
  { label: "Twice", value: 2 },
  { label: "Thrice", value: 3 },
  { label: "4 Times", value: 4 },
  { label: "5 Times", value: 5 },
  { label: "6 Times", value: 6 },
  { label: "7 Times", value: 7 },
];
