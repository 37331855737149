import axios from "axios";
import { server_url } from "../constants/constants";

export async function registerDoctor(doctorData) {
  try {
    const response = await axios.post(server_url + "/doctor", doctorData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function getDoctors() {
  try {
    const response = await axios.get(server_url + "/doctor/getDoctors");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}
export async function updateDoctor(doctorId, doctorData) {
  try {
    const response = await axios.put(server_url + "/doctor/" + doctorId, doctorData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function deleteDoctor(doctorId) {
  try {
    const response = await axios.delete(server_url + "/doctor/" + doctorId);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}