// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line {
  flex: 2 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  padding-bottom: 5px;
  color: gray;
  width: 30px;
  width: 100%;
  height: 80%;
}
.line .title {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/linecomponent-sys-dys/linechart.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EAEA,sDAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;AACJ;AACI;EACE,mBAAA;AACN","sourcesContent":[".line {\n    flex: 2;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    padding: 10px;\n    padding-bottom: 5px;\n    color: gray;\n    width: 30px;\n    width: 100%;\n    height: 80%;\n  \n    .title {\n      margin-bottom: 20px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
