import axios from "axios";
import { server_url } from "../constants/constants";

export async function registerUser(userData) {
  try {
    const response = await axios.post(server_url + "/auth/register", userData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function loginUser(userData) {
  try {
    const response = await axios.post(server_url + "/auth/login", userData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function getUserByEmail(email) {
  try {
    const response = await axios.get(server_url + "/users/email/" + email);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function getUserByEmailDoctor(email) {
  try {
    const response = await axios.get(server_url + "/users/email/doctor/" + email);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function getUsers() {
  try {
    const response = await axios.get(server_url + "/users");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function updateUserByEmail(email, userData) {
  try {
    const response = await axios.put(server_url + "/users/" + email, userData);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function deleteUserByEmail(email) {
  try {
    const response = await axios.delete(server_url + "/users/" + email);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function getRoles() {
  try {
    const response = await axios.get(server_url + "/roles");
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function identifyRole() {
  try {
    const token = localStorage.getItem("token"); // Fetch token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const response = await axios.get(server_url + "/roles/identifyrole/", config);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}