export const roleNames = [
  "Testerrt",
  "BKF",
  "care giver",
  "Social Worker",
  "New Sub Admin",
  "User Program Approve",
  "sub admin",
  "Admin",
];

export const userlist = [
  {
    name: "Prathamesh Mundada",
    email: "prathamesh@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Anish Gade",
    email: "anishgade757@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Kenneth",
    email: "kenneth.nrk123@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Sujal Gupta",
    email: "sujal.gupta2309@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Kaustubh",
    email: "kaustubhgharat06@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "MT",
    email: "shobhad2000@gmail.com",
    role: "Testerrt",
    phone: 9372536732,
  },
  {
    name: "Tushar Bagal",
    email: "tushar.bagal@anjitait.com",
    role: "sub admin",
    phone: 9372536732,
  },
  {
    name: "Ajit Main",
    email: "ajcareful@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Mansi",
    email: "Mansipandey21@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Ashutosh Pandey",
    email: "Ashutosh.mech@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Subadmin",
    email: "y.pooja@rediffmail.com",
    role: "sub admin",
    phone: 9372536732,
  },
  {
    name: "Subadmin",
    email: "abc@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Pooja",
    email: "poojay0091@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Pooja S.A",
    email: "poojay0091@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Admin 2",
    email: "admin@admin.com",
    role: "Admin",
    phone: 9372536732,
  },
  {
    name: "Admin",
    email: "div.chavan298.anjitit@gmail.com",
    role: "Admin",
    phone: 9372536732,
  },
];
export const doctorsList = [
  {
    uniqueCode: "",
    name: "Sheeba C",
    specialities: [],
    email: "sheebanutritionist1234@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Medical Staff",
  },
  {
    uniqueCode: "MANP1234",
    name: "Mansi Pandey",
    specialities: [
      { value: "General Practitioner(GP)", label: "General Practitioner(GP)" },
    ],
    email: "mansipandey21@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "KKE-KH35",
    name: "Kifayti Health",
    specialities: [],
    email: "contactus@kifaytihealth.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "123456ABC",
    name: "Shivam",
    specialities: [],
    email: "shivam.shil@anjitait.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "Dr1435",
    name: "Ashutosh Pandey",
    specialities: [],
    email: "Ashutosh.mech@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "",
    name: "Pooja Medical Staff",
    specialities: [],
    email: "bnh@yahoo.in",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Medical Staff",
  },
  {
    uniqueCode: "98765",
    name: "Pooja",
    specialities: [],
    email: "sdf@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "",
    name: "Pooja Medical Staff",
    specialities: [],
    email: "poojay0091@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Medical Staff",
  },
  {
    uniqueCode: "543",
    name: "New Doctor",
    specialities: [],
    email: "abc@yahoo.in",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "demo1",
    name: "Demo Medical Staff",
    specialities: [],
    email: "y.pooja@rediffmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Medical Staff",
  },
  {
    uniqueCode: "123456",
    name: "Demo",
    specialities: [],
    email: "poojay0091@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "12345",
    name: "Pooja",
    specialities: [],
    email: "poojay0091@gmail.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "122",
    name: "Test Div",
    specialities: [],
    email: "xyz@anjitait.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "12345",
    name: "Test",
    specialities: [],
    email: "tushar.bagal@anjitait.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
  {
    uniqueCode: "4321",
    name: "Nilesh",
    specialities: [],
    email: "nilesh.birje@anjitait.com",
    phoneNo: "",
    practicingAt: "",
    institute: "",
    licenseNo: "",
    doctorsCode: "",
    yearsOfExperience: "",
    address: "",
    photo: null,
    resume: "",
    reference: "",
    description: "",
    role: "Doctor",
  },
];

export const practicingAtList = [
  "Government Hospital",
  "Private Hospital",
  "Hospital Clinic",
  "Private Clinic",
];

export const doctorSpeciality = [
  { value: "Cardiologist", label: "Cardiologist" },
  { value: "Cosmetologist", label: "Cosmetologist" },
  { value: "Dermatologist", label: "Dermatologist" },
  { value: "Diabetologist", label: "Diabetologist" },
  { value: "Endocrinologist", label: "Endocrinologist" },
  { value: "Gastroenterologist", label: "Gastroenterologist" },
  { value: "General Practitioner(GP)", label: "General Practitioner(GP)" },
  { value: "Geriatrician", label: "Geriatrician" },
  { value: "Gerontologist", label: "Gerontologist" },
  { value: "Hepatologist", label: "Hepatologist" },
  {
    value: "Internal Medicine Practitioner",
    label: "Internal Medicine Practitioner",
  },
  { value: "Neonatologist", label: "Neonatologist" },
  { value: "Nephrologist", label: "Nephrologist" },
  { value: "Neuro - Surgeon", label: "Neuro - Surgeon" },
  { value: "Neurologist", label: "Neurologist" },
  {
    value: "Nuclear Medicine Practitioner",
    label: "Nuclear Medicine Practitioner",
  },
  {
    value: "Obstetrician and Gynecologist",
    label: "Obstetrician and Gynecologist",
  },
  { value: "Oncologist", label: "Oncologist" },
  { value: "Ophthalmologist", label: "Ophthalmologist" },
  { value: "Orthopedic Surgeon", label: "Orthopedic Surgeon" },
  {
    value: "Otolaryngologist / ENT specialist",
    label: "Otolaryngologist / ENT specialist",
  },
  { value: "Pediatrician", label: "Pediatrician" },
  { value: "Plastic Surgeon", label: "Plastic Surgeon" },
  { value: "Psychiatrist", label: "Psychiatrist" },
  { value: "Radiologist", label: "Radiologist" },
  { value: "Rheumatologist", label: "Rheumatologist" },
  { value: "Surgical Oncologists", label: "Surgical Oncologists" },
  { value: "Urologist", label: "Urologist" },
  { value: "Venereologist", label: "Venereologist" },
];

export const staffSpeciality = [
  { value: "Dietitian", label: "Dietitian" },
  { value: "Nutritionist", label: "Nutritionist" },
  { value: "Psychologist", label: "Psychologist" },
  { value: "Physiotherapist", label: "Physiotherapist" },
  { value: "Toxicologists", label: "Toxicologists" },
  { value: "Osteopath", label: "Osteopath" },
  { value: "Podiatrist", label: "Podiatrist" },
  { value: "Immunologist", label: "Immunologist" },
  { value: "Parasitologist", label: "Parasitologist" },
  { value: "Pulmonologist", label: "Pulmonologist" },
  { value: "Serologist", label: "Serologist" },
  { value: "Hematologist", label: "Hematologist" },
  { value: "Pathologist", label: "Pathologist" },
  {
    value: "Geneticist / Clinical Geneticist",
    label: "Geneticist / Clinical Geneticist",
  },
];
