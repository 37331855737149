// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,6CAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AACF","sourcesContent":[".navbar {\n  height: 50px;\n  border-bottom: 0.5px solid rgb(231, 228, 228);\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
