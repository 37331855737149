// import React, { useCallback, useState } from "react";
// import { useResizeObserver } from "@wojtekmaj/react-hooks";
// import { pdfjs, Document, Page } from "react-pdf";
// // import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// // import 'react-pdf/dist/esm/Page/TextLayer.css';

// // import "./pdfcomponent.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// const options = {
//   cMapUrl: "/cmaps/",
//   standardFontDataUrl: "/standard_fonts/",
// };

// const resizeObserverOptions = {};

// const maxWidth = 800;

// export default function PdfComponent() {
//   const [file, setFile] = useState(null);
//   const [numPages, setNumPages] = useState();
//   const [containerRef, setContainerRef] = useState(null);
//   const [containerWidth, setContainerWidth] = useState();
//   const [pageNumber, setPageNumber] = useState(1);

//   const onResize = useCallback((entries) => {
//     const [entry] = entries;

//     if (entry) {
//       setContainerWidth(entry.contentRect.width);
//     }
//   }, []);

//   useResizeObserver(containerRef, resizeObserverOptions, onResize);

//   function onFileChange(event) {
//     const { files } = event.target;

//     if (files && files[0]) {
//       setFile(files[0] || null);
//     }
//   }

//   function onDocumentLoadSuccess({ numPages: nextNumPages }) {
//     setNumPages(nextNumPages);
//   }

//   const nextPage = () => {
//     if (pageNumber < numPages) {
//       setPageNumber(pageNumber + 1);
//     }
//   };

//   const prevPage = () => {
//     if (pageNumber > 1) {
//       setPageNumber(pageNumber - 1);
//     }
//   };

//   return (
//     <div className="Example">
//       <header>
//         <h1>react-pdf sample page</h1>
//       </header>
//       <div className="Example__container">
//         <div className="Example__container__load">
//           <label htmlFor="file">Load from file:</label>{" "}
//           <input onChange={onFileChange} type="file" />
//         </div>
//         {/* <div className="Example__container__document" ref={setContainerRef}>
//           <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
//             {Array.from(new Array(numPages || 0), (el, index) => (
//               <Page
//                 key={`page_${index + 1}`}
//                 pageNumber={index + 1}
//                 width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
//               />
//             ))}

//           </Document>
//           <p>
//             Page {pageNumber} of {numPages}
//           </p>
//         </div> */}

//         <div className="Example__container__document" ref={setContainerRef}>
//           <Document
//             file={file}
//             onLoadSuccess={onDocumentLoadSuccess}
//             options={options}
//           >
//             <Page
//               key={`page_${pageNumber}`}
//               pageNumber={pageNumber}
//               width={
//                 containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
//               }
//             />
//           </Document>
//           <div>
//             <p>
//               Page {pageNumber} of {numPages}
//             </p>
//             <div>
//               <button onClick={prevPage} disabled={pageNumber <= 1}>
//                 Previous
//               </button>
//               <button onClick={nextPage} disabled={pageNumber >= numPages}>
//                 Next
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
