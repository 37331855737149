import React from "react";
import ThumbnailModal from "./ThumbnailModal";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { server_url } from "../../constants/constants";
import { insertAlert } from "../../ApiCalls/appAlerts";
import SendMessage from "./SendMessage";

const CommentConatainer = ({ comments, closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState("");
  const [comment, setComment] = useState(null);
  const [smessage, setSmessage] = useState(false);

  const openSendMessage = () => {
    setSmessage(true);
  };

  const closeSendMessage = () => {
    setSmessage(false);
  };

  const openThumbnailModal = (image, comment) => {
    setComment(comment);
    setIsModalOpen(true);
    setImage(image);
  };
  const closeThumbnailModal = async () => {
    setIsModalOpen(false);
  };

  const consultDoctor = async () => {
    // console.log("comment[0]:", comments[0])
    const patientId = comments[0].userId;
    const doctorEmail = localStorage.getItem("email");
    const mess = "";
    const category = "Consult Doctor";

    try {
      await insertAlert(doctorEmail, patientId, category, mess);
    } catch (error) {
      console.log(error);
    }
  };

  var coms = comments.reverse();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-black overflow-y-auto">
      <div className="p-7 ml-4 mr-4 mt-4 bg-white shadow-md border-t-4 border-primary rounded z-50 w-[80%] h-[100vh]">
        <div className="header flex justify-between items-center border-b pb-2 mb-4">
          <h2 className="text-2xl font-bold">Important Alerts</h2>
          <Link to={"/userProfile/" + comments[0]?.userId}>
            <div
              className="rounded-lg text-primary border-2  border-primary w-40 py-2 justify-center flex shadow-lg"
              style={{
                cursor: "pointer",
              }}
            >
              View Profile
            </div>
          </Link>
          <div
            className="rounded-lg text-white border-2  bg-red-800 w-40 py-2 justify-center flex shadow-lg"
            onClick={consultDoctor}
          >
            Consult Doctor
          </div>
          <div
            className="rounded-lg text-white border-2 bg-primary border-primary w-40 py-2 justify-center flex shadow-lg"
            style={{
              cursor: "pointer",
            }}
            onClick={openSendMessage}
          >
            Send Message
          </div>
          <div
            className="rounded-lg text-red-800 border-2  border-red-800 w-40 py-2 justify-center flex shadow-lg"
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            Close
          </div>
        </div>
        {isModalOpen && (
          <ThumbnailModal
            closeModal={closeThumbnailModal}
            image={image}
            comment={comment}
          />
        )}
        {smessage && (
          <SendMessage
            closeModal={closeSendMessage}
            patientid={comments[0].userId}
          />
        )}
        {coms.map((comment) => (
          <div className="p-4 shadow-md hover:shadow-lg border rounded-lg border-gray-200 transition duration-300 ease-in-out m-1">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div className="mb-4">
                  <label
                    className="block text-sm font-semibold mb-2 text-red-400"
                    style={
                      {
                        // color: `${comment.color}`
                      }
                    }
                  >
                    {comment.fileType}
                  </label>
                  <label className="block text-sm font-bold mb-2">
                    PATIENT COMMENT - "{comment.content}"
                  </label>
                </div>
              </div>
              {/* {comment.url && (
                                <img src={comment.url} alt="Thumbnail" className="w-14 h-14  mr-4 shadow-md" onClick={
                                    () => {
                                        openThumbnailModal(comment.url);
                                    }
                                
                                } />
                            )} */}
              <div
                className="rounded-lg text-white border-2 bg-primary border-primary w-40 py-2 justify-center flex cursor-pointer shadow-lg hover:bg-primary-dark hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                onClick={() => {
                  openThumbnailModal(comment.url, comment);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                View/Comment
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentConatainer;
