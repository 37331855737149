// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserLabReports {
  display: flex;
}
.UserLabReports .UserLabReportsContainer {
  flex: 5 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/UserLabReports/UserLabReports.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,SAAA;AACR","sourcesContent":[".UserLabReports {\n    display: flex;\n\n    .UserLabReportsContainer {\n        flex: 5;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
