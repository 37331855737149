// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Userprescription {
  display: flex;
}
.Userprescription .UserprescriptionContainer {
  flex: 5 1;
}
.Userprescription img {
  width: 75px;
  height: 75px;
  margin: 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Userprescription/Userprescription.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,SAAA;AACR;AACI;EACI,WAAA;EACA,YAAA;EACA,WAAA;AACR","sourcesContent":[".Userprescription {\n    display: flex;\n\n    .UserprescriptionContainer {\n        flex: 5;\n    }\n    img{\n        width: 75px;\n        height: 75px;\n        margin: 3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
