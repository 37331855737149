import React, { useState } from "react";
import axios from "axios";
import { server_url } from "../../constants/constants";

const NameModal = ({
  closeEditModal,
  onSuccess,
  initialData,
  updateData,
  name: initialName,
  number: initialNumber,
  dob: initialDob,
  
}) => {
  const [name, setName] = useState(initialName || "");
  const [number, setNumber] = useState(initialNumber || "");
  const [dob, setDob] = useState(initialDob || "");

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toDateString();
  };

  const handleUpdate = async () => {
    const updatedUserData = {
      id: initialData.id,
      name: name,
      number: number,
      dob: dob,
    };
    console.log(updatedUserData);
    try {

      await axios.put(`${server_url}/patient/updatePatient`, updatedUserData)
      onSuccess();
      

      // await axios
      // .put(`${server_url}/patient/updatePatient`, updatedUserData)
      // .then(async (response) => {
      //   console.log("User data updated successfully:", response.data);
      //   await onSuccess()
      //   closeEditModal();
      // })
      // .catch((error) => {
      //   console.error("Error updating user data:", error);
      // });
      
    } catch (error) {
      console.log(error)
    }finally{
      closeEditModal();
    }
    
  };

  const handleCancel = () => {
    closeEditModal();
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-black">
        <div className="p-7 ml-4 mr-4 mt-4 bg-white shadow-md border-t-4 border-primary rounded z-50 overflow-y-auto">
          <div className="header flex justify-between items-center border-b pb-2 mb-4">
            <h2 className="text-2xl font-bold">Update User Details</h2>
          </div>
          <div className="p-4">
            <div className="mb-4">
              <label htmlFor="">Name: </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full border-2 py-2 px-3 rounded focus:outline-none focus:border-primary"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="">Number: </label>
              <input
                type="text"
                id="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                className="w-full border-2 py-2 px-3 rounded focus:outline-none focus:border-primary"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="">DOB: </label>
              <input
                type="date"
                id="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="w-full border-2 py-2 px-3 rounded focus:outline-none focus:border-primary"
              />
            </div>
          </div>
          <div className="flex justify-end p-4">
            <button
              onClick={handleUpdate}
              className="bg-primary text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              UPDATE
            </button>
            <button
              onClick={handleCancel}
              className="border-2 border-primary text-primary py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NameModal;
