import axios from "axios";
import { server_url } from "../constants/constants";

export async function createQuestion(questionData) {
  try {
    const token = localStorage.getItem("token"); // Fetch token from local storage
    const response = await axios.post(server_url + "/questions", questionData, {
      headers: {
        Authorization: `Bearer ${token}`, // Send token in headers
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function getQuestions() {
  try {
    const token = localStorage.getItem("token"); // Fetch token from local storage
    const response = await axios.get(server_url + "/questions", {
      headers: {
        Authorization: `Bearer ${token}`, // Send token in headers
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function updateQuestion(id, questionData) {
  try {
    const token = localStorage.getItem("token"); // Fetch token from local storage
    const response = await axios.put(
      server_url + "/questions/" + id,
      questionData,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Send token in headers
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}

export async function deleteQuestion(id) {
  try {
    const token = localStorage.getItem("token"); // Fetch token from local storage
    const response = await axios.delete(server_url + "/questions/" + id, {
      headers: {
        Authorization: `Bearer ${token}`, // Send token in headers
      },
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message };
  }
}
