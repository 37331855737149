import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { server_url } from "../../../constants/constants";
import profileImg from "../../../assets/pp.png";
import { useSelector } from "react-redux";
import { BsTrash, BsCloudDownload } from "react-icons/bs";
import { canExportPatient } from "../../../ApiCalls/patientAPis";

export default function PatientList({ data }) {
  const recordsPerPage = 10;
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [medicalTeamNames, setMedicalTeamNames] = useState({});
  const [adminNames, setAdminNames] = useState({});
  const [canExport, setCanExport] = useState(false);
  const navigate = useNavigate();
  const role = useSelector((state) => state.permission);
  async function checkCanExport() {
    try {
      console.log("Checking export permission...");
      const response = await canExportPatient();
      if (response.success) {
        setCanExport(true);
      }
    } catch (error) {
      console.error("Error checking export permission:", error);
    }
  }

  useEffect(() => {
    setFilteredData(data);
    setCurrentPage(1);
  }, [data]);

  useEffect(() => {
    checkCanExport();
  }, []);

  useEffect(() => {
    const fetchMedicalTeamNames = async () => {
      const promises = filteredData.map(async (row) => {
        try {
          const response = await axios.get(
            `${server_url}/assignedDoctor/getDoctor/${row.id}`
          );
          const doctorNames = response.data.data.map((doctor) => doctor.name);
          setMedicalTeamNames((prevNames) => ({
            ...prevNames,
            [row.id]: doctorNames.join(", "),
          }));
        } catch (error) {
          console.error("Error fetching medical team names:", error);
        }
      });
      await Promise.all(promises);
    };

    const fetchAdminNames = async () => {
      const promises = filteredData.map(async (row) => {
        try {
          const response = await axios.get(
            `${server_url}/assignedAdmin/getAdmin/${row.id}`
          );
          const adminNames = response.data.data.map((admin) => admin.firstname);
          setAdminNames((prevNames) => ({
            ...prevNames,
            [row.id]: adminNames.join(", "),
          }));
        } catch (error) {
          console.error("Error fetching admin names:", error);
        }
      });
      await Promise.all(promises);
    };

    fetchMedicalTeamNames();
    fetchAdminNames();
  }, [filteredData]);

  const handleFilter = (event) => {
    const filtered = data.filter((row) =>
      row.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleRowClick = (row) => {
    navigate(`/userProfile/${row.id}`, { state: row });
  };

  const pageCount = Math.ceil(filteredData.length / recordsPerPage);
  const offset = (currentPage - 1) * recordsPerPage;
  const currentPageData = filteredData.slice(offset, offset + recordsPerPage);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const dateObject = new Date(dateString);
    return dateObject.toISOString().split("T")[0];
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server_url}/patient/deletePatient/${id}`);
      // Remove the deleted patient from the UI
      setFilteredData(filteredData.filter((patient) => patient.id !== id));
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  };

  return (
    <div className="w-5/6 p-7 ml-4 mr-4 mt-10 mb-4 bg-white shadow-md border-t-4 border-primary ">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Search by Name"
            onChange={handleFilter}
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
          <button className="ml-2 px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600">
            <i className="fas fa-search"></i> Search
          </button>
        </div>
        <div>
          <div className="text-lg font-semibold">
            Total Users: {filteredData.length}
          </div>
          {canExport && (
            <div>
              <a
                className="p-2 rounded-md w-full text-center block bg-primary text-white"
                download={true}
                href={server_url + "/patientdata/export"}
              >
                Export All
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          {/* Table Header */}
          <thead>
            <tr className="border-b-2 border-black">
              <th className="px-4 py-2">Profile Photo</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Number</th>
              <th className="px-4 py-2">Registration Date</th>
              <th className="px-4 py-2">Program</th>
              <th className="px-4 py-2">Medical Team</th>
              <th className="px-4 py-2">Assigned To</th>

              {role?.canDeletePatients ||
                (canExport && <th className="px-4 py-2">Action</th>)}
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {currentPageData.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleRowClick(row)}
                className="cursor-pointer border-b-2 hover:bg-gray-100"
              >
                <td className="px-4 py-2 text-center">
                  <img
                    src={row.profile ? row.profile : profileImg}
                    alt="Profile"
                    style={{ width: "75px", height: "75px" }} // Adjust size as needed
                  />
                </td>
                <td className="px-4 py-2">{row.name ? row.name : ""}</td>
                <td className="px-4 py-2">{row.number ? row.number : ""}</td>
                <td className="px-4 py-2 text-center">
                  {row.registered_date ? formatDate(row.registered_date) : ""}
                </td>
                <td className="px-4 py-2">{row.program ? row.program : ""}</td>
                <td className="px-4 py-2">
                  {medicalTeamNames[row.id] &&
                    medicalTeamNames[row.id]
                      .split(",")
                      .map((name, index) => <div key={index}>{name}</div>)}
                  <div>
                    {role?.manageRoles !== 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/userMedicalTeam/${row.id}`, {
                            state: row,
                          });
                        }}
                        className="bg-primary text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      >
                        MANAGE
                      </button>
                    )}
                  </div>
                </td>
                <td className="px-4 py-2">
                  {adminNames[row.id] &&
                    adminNames[row.id]
                      .split(",")
                      .map((name, index) => <div key={index}>{name}</div>)}
                  <div>
                    {role?.manageRoles !== 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/userListManage/${row.id}`, { state: row });
                        }}
                        className="bg-primary text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      >
                        MANAGE
                      </button>
                    )}
                  </div>
                </td>

                <td className="px-6 py-4 text-3xl flex">
                  {canExport && (
                    <a
                      className="text-primary inline-block mx-2"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      href={server_url + "/patientdata/export/" + row.id}
                      download={true}
                    >
                      <BsCloudDownload />
                    </a>
                  )}
                  {role?.canDeletePatients && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click event from triggering
                        handleDelete(row.id);
                      }}
                      className="text-[#ff0000] inline-block mx-2"
                    >
                      <BsTrash />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      {pageCount >= 0 && (
        <div className="pagination mt-4 flex items-center justify-end">
          <button
            onClick={() => setCurrentPage((prev) => prev - 1)}
            disabled={currentPage === 1}
          >
            {"\u2190"}
          </button>
          <span>{`Page ${currentPage} of ${pageCount}`}</span>
          <button
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={currentPage === pageCount}
          >
            {"\u2192"}
          </button>
        </div>
      )}
    </div>
  );
}
