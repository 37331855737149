import { server_url } from "../constants/constants";
import axios from "axios";
const insertAlert = async (doctorEmail, patientId, category, mess) => {
    console.log(doctorEmail, patientId, category, mess);
    const { data } = await axios.post(`${server_url}/app/appAlerts/insertAlert`, {
        doctorEmail,
        patientId,
        category,
        mess
    });
    return data;
};


export { insertAlert };